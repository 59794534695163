<script setup lang="ts">
const { t } = useI18n();
const switchLocalePath = useSwitchLocalePath();
</script>

<template>
  <div
    class="flex h-screen flex-col items-center justify-center bg-gradient-to-b from-[#53BE7E] to-[#C1E8D0]"
  >
    <div class="h-fit w-2/5 max-w-[700px] rounded-3xl bg-white px-14 py-10">
      <div class="mb-10 h-[54px] w-full">
        <img
          class="mx-auto h-full"
          src="/logo-aifinyochat-full-on-light.png"
          alt="logo"
        />
      </div>
      <slot />
    </div>
    <div class="mt-9 flex flex-col gap-y-4">
      <p class="text-center text-misc-text-subtle">
        {{ t("auth.legal.title") }}
        <!--        Die Übertragung ist durch eine SSL-Verschlüsselung geschützt.-->
      </p>
      <p class="text-center text-misc-text-subtle">
        @2023
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.aifinyo.de/"
          external
        >
          {{ t("auth.legal.name") }}
        </NuxtLink>
        •
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.aifinyochat.ai/impressum/"
          external
        >
          {{ t("auth.legal.imprint") }}
        </NuxtLink>
        •
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.aifinyochat.ai/agb/"
          external
        >
          {{ t("auth.legal.terms") }}
        </NuxtLink>
        •
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.aifinyochat.ai/datenschutzerklaerung/"
          external
        >
          {{ t("auth.legal.privacy") }}
        </NuxtLink>
      </p>
      <p class="text-center text-misc-text-subtle">
        <nuxt-link
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          :to="switchLocalePath('de')"
          external
        >
          <!--          Detusch-->
          {{ t("languages.de") }}
        </nuxt-link>
        •
        <nuxt-link
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          :to="switchLocalePath('en')"
          external
        >
          {{ t("languages.en") }}
        </nuxt-link>
      </p>
    </div>
  </div>
</template>
